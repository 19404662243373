import React,{useState} from "react";
import './training.css'; 
import NavBar from "../components/NavBar/index.js";
import Banner from "../components/SectionBanner/Banner.js";
import AboutImg from "../images/app.jpg" 
import Fade from 'react-reveal/Fade'; 
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
const MobileAppDevelopment = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
 
  return (
    <>
       <Sidebar isOpen={isOpen} toggle={toggle} />
       <NavBar toggle={toggle} />
      <Banner cName="banner-mid" bannerImg={AboutImg} title="Mobile App Development at Digi Trick" btnClass="hide" />
      <div className="training-page">
        <Fade bottom>
          <h1>Mobile App Development Services at Digi Trick Consultancy</h1>
          <br/>
          <p>
            In an increasingly mobile world, having a powerful and user-friendly mobile app is essential for businesses looking to stay competitive. At Digi Trick Consultancy, we specialize in creating custom mobile applications that are designed to meet the unique needs of your business and engage your customers effectively. Whether you’re looking to build an app from scratch or enhance an existing one, our team of experts is here to help you bring your vision to life.
          </p>
        </Fade>

        <section className="training-section">
          <Fade bottom>
          <br/><br/>
            <h2>Our Mobile App Development Services</h2>

            <h3>1. Custom Mobile App Development</h3>
            <p>
              Every business has unique goals, and your mobile app should reflect that. Our Custom Mobile App Development service is focused on creating apps that are perfectly aligned with your business needs, providing a seamless user experience across all devices.
              <ul>
              <li>Fully customized app design and development</li>
              <li>Support for iOS, Android, and cross-platform development</li>
              <li>Integration with existing systems and third-party services</li>
              <li>Focus on performance, scalability, and security</li>
              <li>User-friendly interfaces with intuitive navigation</li>
            </ul>
            </p>
            
          </Fade>

          <Fade bottom>
            <h3>2. UI/UX Design</h3>
            <p>
              A great app is more than just functional—it’s a pleasure to use. Our UI/UX Design services are dedicated to creating visually appealing, user-friendly interfaces that enhance the overall user experience, ensuring your app stands out in the crowded marketplace.
              <ul>
              <li>Research-driven design processes</li>
              <li>Wireframing and prototyping</li>
              <li>User journey mapping and flow design</li>
              <li>Responsive design for various screen sizes</li>
              <li>Iterative testing and refinement for optimal usability</li>
            </ul>
            </p>
            
          </Fade>

          <Fade bottom>
            <h3>3. Cross-Platform App Development</h3>
            <p>
              Reaching users across different platforms is crucial for maximizing your app’s impact. Our Cross-Platform App Development services allow you to build apps that work seamlessly on both iOS and Android, reducing development time and costs while ensuring consistent performance.
              <ul>
              <li>Development using frameworks like React Native and Flutter</li>
              <li>Unified codebase for iOS and Android</li>
              <li>Consistent user experience across platforms</li>
              <li>Faster development and deployment</li>
              <li>Cost-effective solutions for multi-platform support</li>
            </ul>
            </p>
           
          </Fade>

          <Fade bottom>
            <h3>4. App Testing and Quality Assurance</h3>
            <p>
              Ensuring your app works flawlessly is our top priority. Our App Testing and Quality Assurance services involve rigorous testing to identify and fix any issues before your app goes live, ensuring a smooth and bug-free user experience.
              <ul>
              <li>Comprehensive testing across multiple devices and platforms</li>
              <li>Functional, usability, and performance testing</li>
              <li>Security testing to protect user data</li>
              <li>Continuous integration and automated testing tools</li>
              <li>Detailed reporting and bug fixing</li>
            </ul>
            </p>
           
          </Fade>

          <Fade bottom>
            <h3>5. App Maintenance and Support</h3>
            <p>
              Launching your app is just the beginning. Our App Maintenance and Support services ensure that your app continues to perform optimally, with regular updates, performance monitoring, and ongoing support to keep it up-to-date with the latest technologies and user expectations.
              <ul>
              <li>Regular updates and feature enhancements</li>
              <li>Performance monitoring and optimization</li>
              <li>Bug fixes and security patches</li>
              <li>User feedback analysis and improvements</li>
              <li>24/7 support and troubleshooting</li>
            </ul>
            </p>
            
          </Fade>

          <Fade bottom>
            <h3>6. App Store Optimization (ASO)</h3>
            <p>
              Getting your app noticed in app stores is key to its success. Our App Store Optimization (ASO) services focus on improving your app’s visibility and ranking in app store search results, driving more downloads and engagement.
              <ul>
              <li>Keyword research and optimization</li>
              <li>Compelling app descriptions and screenshots</li>
              <li>A/B testing of app store elements</li>
              <li>Monitoring and analyzing app store performance</li>
              <li>Strategic updates to improve rankings</li>
            </ul>
            </p>
            
          </Fade>
        </section>

        <section className="training-section">
          <Fade bottom>
          <br/><br/>
            <h2>Why Choose Digi Trick Consultancy for Mobile App Development?</h2>
            <ul>
              <li>Experienced Team: Our developers have extensive experience in creating high-quality mobile apps for various industries.</li>
              <li>Tailored Solutions: We provide customized app development services that cater to your specific business needs.</li>
              <li>End-to-End Service: From concept to deployment and maintenance, we offer comprehensive mobile app development solutions.</li>
              <li>Focus on Quality: We prioritize delivering apps that are not only functional but also provide an outstanding user experience.</li>
              <li>Ongoing Support: We offer continuous support and maintenance to ensure your app remains up-to-date and effective.</li>
            </ul>
          </Fade>
        </section>

        <section className="training-section" id="last">
          <Fade bottom><br/><br/>
            <h2>Get Started with Digi Trick Consultancy’s Mobile App Development Services Today</h2>
            <p>
              Ready to turn your app idea into reality? Digi Trick Consultancy is here to help. Whether you need a new mobile app developed from scratch or want to enhance an existing one, our team is ready to deliver a solution that meets your business goals. Contact us today to discuss your mobile app development needs and take the first step toward creating an app that drives success for your business.
            </p>
          </Fade>
        </section>
      </div>
      <Footer/>
    </>
  );
};

export default MobileAppDevelopment;
